<template>
  <div class="class-ranking">
    <v-toolbar color="transparent" class="elevation-0" dense>
      <v-toolbar-title>本校節電排行</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-row>
      <v-col>
        <v-select
          v-model="rankType"
          :items="sortOptions"
          label="排序方式"
          solo
          flat
          dense
          hide-details
          @change="changeRankType()"
        ></v-select>
      </v-col>
      <v-col cols="auto">
        <classRankfilterCard v-model="query" :schoolId="schoolId" />
      </v-col>
      <v-col cols="auto">
        <v-btn
          class="px-8"
          color="primary"
          :loading="loadingExport"
          @click="exportReport"
          >下載報表</v-btn
        >
      </v-col>
    </v-row>
    <v-list class="sort-list transparent">
      <v-list-item class="item-header" dense>
        <v-list-item-icon style="align-items: center; align-self: center">
          <v-list-item-subtitle>名次</v-list-item-subtitle>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle>班級</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content v-if="rankType == 'yoy' || rankType == 'mom'">
          <v-list-item-subtitle>節電量 (度）</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content v-if="rankType == 'consumption'">
          <v-list-item-subtitle>用電量</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content>
          <v-list-item-subtitle>上次排名</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <div class="item-body">
        <v-sheet
          v-if="!filterDate.length"
          class="text-center ems-blue-grey--text pa-2"
          color="ems-light-grey"
          height="100%"
          rounded=""
        >
          ( 無資料 )
        </v-sheet>
        <v-list-item
          v-for="(item, i) in filterDate"
          :key="i"
          :class="`item-sort-${item.ranking}`"
        >
          <v-list-item-icon>
            <v-avatar size="1.4rem">
              <span class="white--text">{{ item.ranking }}</span>
            </v-avatar>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-content>
            {{ item.energy }}
          </v-list-item-content>
          <v-list-item-content>
            <div>
              <span class="mr-4">{{ item.lastRanking }}</span>
              <img
                v-if="item.ranking > item.lastRanking"
                :src="require('@/assets/image/down-arrow.svg')"
                style="vertical-align: middle"
              />
              <v-icon v-else-if="item.ranking === item.lastRanking"
                >mdi-minus</v-icon
              >
              <img
                v-else
                :src="require('@/assets/image/up-arrow.svg')"
                style="vertical-align: middle"
              />
            </div>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </div>
</template>

<script>
import Competition from '@/api/ems/Competition'
import classRankfilterCard from './classRankfilterCard'
import { mapGetters } from 'vuex'

export default {
  name: undefined,
  mixins: [],
  components: {
    classRankfilterCard
  },
  props: {
    schoolId: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      filterMenu: false,
      rankType: 'consumption',
      sortOptions: [
        {
          text: '用電量',
          value: 'consumption'
        },
        {
          text: '去年同期節電量',
          value: 'yoy'
        },
        {
          text: '上月相比節電量',
          value: 'mom'
        }
      ],
      query: {
        groupId: null
      },
      data: {
        consumption: [],
        yoy: [],
        mom: []
      },
      loadingExport: false
    }
  },
  computed: {
    ...mapGetters({
      groups: 'groups/groups',
      pageData: 'page/pageData'
    }),
    filterDate() {
      if (!this.data || !this.data[this.rankType]) {
        return []
      }
      return this.data[this.rankType]
    },
    schoolName() {
      return this.pageData.name || ''
    }
  },
  watch: {
    query: {
      deep: true,
      handler: function (val, oldVal) {
        this.getCompetition()
      }
    }
  },
  created: function () {},
  mounted: function () {
    this.getCompetition()
  },
  destroyed() {},
  methods: {
    getCompetition() {
      Competition.classes(this.schoolId, this.query).then((response) => {
        if (!response.data) {
          return
        }
        this.data = response.data
      })
    },
    changeRankType() {
      this.$emit('changeRankType', this.rankType)
    },
    async exportReport() {
      this.loadingExport = true
      try {
        const response = await Competition.exportClassesRank(
          this.schoolId,
          'csv',
          {
            type: this.rankType,
            ...(this.query.groupId && { groupId: this.query.groupId })
          }
        )
        this.downloadFile(response)
      } catch (e) {
        console.error(e)
      }
      this.loadingExport = false
    },
    downloadFile(response, fileType = 'csv') {
      if (fileType === 'csv') {
        const url = window.URL.createObjectURL(
          new Blob([(response.data ? '\ufeff' : '') + response.data], {
            type: 'text/csv;charset=utf-8;'
          })
        )
        const link = document.createElement('a')
        link.href = url
        let fileName = this.getResponseFilename(fileType)
        link.setAttribute('download', fileName) // or any other extension
        document.body.appendChild(link)
        link.click()
      } else if (fileType === 'json') {
        var dataStr =
          'data:text/json;charset=utf-8,' +
          encodeURIComponent(JSON.stringify(response.data))
        var downloadAnchorNode = document.createElement('a')
        downloadAnchorNode.setAttribute('href', dataStr)
        let fileName = this.getResponseFilename(fileType)
        downloadAnchorNode.setAttribute('download', fileName)
        document.body.appendChild(downloadAnchorNode) // required for firefox
        downloadAnchorNode.click()
        downloadAnchorNode.remove()
      }
    },
    getResponseFilename(fileType) {
      return `${this.schoolName ? this.schoolName + '_' : ''}${
        this.query.groupId
          ? this.groups.find(({ id }) => id === this.query.groupId).name + '_'
          : ''
      }${
        this.sortOptions.find(({ value }) => value === this.rankType).text ||
        '節電'
      }排行.${fileType}`
    }
  }
}
</script>

<style lang="scss" scoped>
.sort-list::v-deep {
  .item-header {
    color: #ddd;
  }
  .item-body {
    max-height: 300px;
    overflow-y: auto;
  }

  @media (min-width: 960px) {
    .item-body {
      max-height: 630px;
    }
  }

  .v-list-item:not(.item-header) {
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 5px;
    .v-list-item__title {
      color: var(--v-accent-darken1);
      font-weight: bold;
    }
  }

  .item-sort-1 {
    background: linear-gradient(65deg, #2fd9ff 0%, #00ddc9 50%);
    .v-avatar {
      background-color: var(--v-warning-lighten2) !important;
      border-color: var(--v-warning-lighten2) !important;
    }
  }

  .item-sort-2,
  .item-sort-3 {
    .v-avatar {
      background-color: var(--v-primary-base) !important;
      border-color: var(--v-primary-base) !important;
    }
  }

  .v-avatar {
    background-color: var(--v-ems-pale-grey-darken3) !important;
    border-color: var(--v-ems-pale-grey-darken3) !important;
  }
}
</style>
