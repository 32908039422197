import settings from '@/settings'
import Qs from 'qs'

/**
 * 節電排行
 */
class Competition {
  /**
   * 行政區節電排行
   */
  dist(cityId, params) {
    return axios.get(
      settings.api.fullPath + `/ems/competition/city/${cityId}/district/`,
      {
        params: params
      }
    )
  }

  /**
   * 同級學校節電排行榜
   */
  school(schoolId, params) {
    return axios.get(
      settings.api.fullPath + `/ems/competition/school/${schoolId}`,
      {
        params: params
      }
    )
  }

  /**
   * 本校班級節電排行
   */
  classes(schoolId, params) {
    return axios.get(
      settings.api.fullPath + `/ems/competition/school/${schoolId}/class`,
      {
        params: params
      }
    )
  }

  /**
   * 本校同期累積電度比較
   */
  schoolEnergy(schoolId, params) {
    return axios.get(
      settings.api.fullPath + `/ems/competition/school/${schoolId}/energy`,
      {
        params: params
      }
    )
  }

  /**
   * 行政區學校節電排行
   */
  districtSchool(districtId, params) {
    return axios.get(
      settings.api.fullPath + `/ems/competition/district/${districtId}/school`,
      {
        params: params,
        paramsSerializer: function (params) {
          return Qs.stringify(params, { arrayFormat: 'repeat' })
        }
      }
    )
  }

  /**
   * 學校排名預測
   */
  schoolPredict(schoolId, params) {
    return axios.get(
      settings.api.fullPath + `/ems/competition/school/${schoolId}/predict`,
      {
        params: params
      }
    )
  }

  /**
   * 班級排名預測
   */
  classPredict(classId, params) {
    return axios.get(
      settings.api.fullPath + `/ems/competition/class/${classId}/predict`,
      {
        params: params
      }
    )
  }

  /**
   * 本校節電排行 下載
   */
  exportClassesRank(schoolId, fileType, params) {
    return axios.get(
      settings.api.fullPath +
        `/ems/competition/school/${schoolId}/class.${fileType}`,
      {
        params: params
      }
    )
  }
}

var competition = new Competition()
export default competition
