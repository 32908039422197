<template>
  <v-card>
    <v-card-title class="pt-8">
      <span class="accent--text font-weight-bold ma-auto">{{
        pageData.name
      }}</span>
    </v-card-title>
    <v-card-text>
      <div class="mb-4">
        <v-img
          v-if="ranking"
          contain
          max-height="80"
          max-width="80"
          :src="classMedalSrc"
          class="mx-auto"
        >
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-col cols="auto">
              <div class="pb-5 accent--text font-weight-bold text-h5">
                {{ ranking }}
              </div>
            </v-col>
          </v-row>
        </v-img>
      </div>
      <v-row class="mx-2">
        <v-col cols="6">
          <div class="info-data-title">班級總用電</div>
          <div class="info-data-text">
            {{ numeral(consumption).format('0.0') }} kWh
          </div>
        </v-col>
        <v-col cols="6">
          <div class="info-data-title">班級同期節電量</div>
          <div class="info-data-text">{{ numeral(yoy).format('0.0') }} kWh</div>
        </v-col>
        <!-- <v-col cols="12">
          <div class="info-data-title">套用標籤</div>
          <div>
            <v-chip
              color="blue lighten-4"
              class="mr-2 mb-2"
            >班級</v-chip>
            <v-chip
              color="blue lighten-4"
              class="mr-2 mb-2"
            >班級</v-chip>
            <v-chip
              color="blue lighten-4"
              class="mr-2 mb-2"
            >班級</v-chip>
            <v-chip
              color="blue lighten-4"
              class="mr-2 mb-2"
            >班級</v-chip>
            <v-chip
              color="blue lighten-4"
              class="mr-2 mb-2"
            >班級</v-chip>
            <v-chip
              color="blue lighten-4"
              class="mr-2 mb-2"
            >班級</v-chip>
            <v-chip
              color="blue lighten-4"
              class="mr-2 mb-2"
            >班級</v-chip>
            <v-chip
              color="blue lighten-4"
              class="mr-2 mb-2"
            >班級</v-chip>
            <v-chip
              color="blue lighten-4"
              class="mr-2 mb-2"
            >班級</v-chip>
          </div>
        </v-col> -->
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import Competition from '@/api/ems/Competition'

export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    schoolId: {
      type: String,
      required: true
    },
    classId: {
      type: String,
      required: true
    },
    rankType: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      classItems: [],
      medalsIcons: {
        1: require('@/assets/image/medals/1.svg'),
        2: require('@/assets/image/medals/2.svg'),
        3: require('@/assets/image/medals/3.svg'),
        normal: require('@/assets/image/medals/normal.svg')
      }
    }
  },
  computed: {
    ...mapGetters({
      pageData: 'page/pageData'
    }),
    classMedalSrc() {
      if (!this.ranking) {
        return this.medalsIcons['normal']
      }
      return Number(this.ranking) >= 4
        ? this.medalsIcons['normal']
        : this.medalsIcons[Number(this.ranking)]
    },
    ranking() {
      if (!this.classItems || !this.classItems[this.rankType]) {
        return null
      }
      const classes = this.classItems[this.rankType].find(
        (item) => item.id === this.classId
      )
      return classes ? classes.ranking || null : null
    },
    consumption() {
      if (!this.classItems || !this.classItems.consumption) {
        return null
      }
      const classes = this.classItems.consumption.find(
        (item) => item.id === this.classId
      )
      return classes ? classes.energy || null : null
    },
    yoy() {
      if (!this.classItems || !this.classItems.yoy) {
        return null
      }
      const classes = this.classItems.yoy.find(
        (item) => item.id === this.classId
      )
      return classes ? classes.energy || null : null
    }
  },
  watch: {},
  created: function () {},
  mounted: function () {
    this.getCompetition()
  },
  destroyed() {},
  methods: {
    getCompetition() {
      Competition.classes(this.schoolId).then((response) => {
        if (!response.data) {
          return
        }
        this.classItems = response.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.info-data-text {
  color: var(--v-accent-base);
}
</style>
