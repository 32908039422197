<template>
  <v-row justify="center">
    <v-col cols="12" md="10" lg="7">
      <v-row v-if="pageData && pageData.schoolId">
        <v-col cols="12" md="4">
          <infoCard
            :schoolId="pageData.schoolId"
            :classId="tagId"
            :rankType="rankType"
            class="mt-12 ems-opacity-75-bg elevation-0"
          />
          <forecastCard :classId="tagId" />
        </v-col>
        <v-col cols="12" md="8">
          <classRank
            :schoolId="pageData.schoolId"
            @changeRankType="changeRankType"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import classRank from '@/views/school/comparison/classRank'
import infoCard from './infoCard'
import forecastCard from './forecastCard'

export default {
  name: undefined,
  mixins: [],
  components: {
    classRank,
    infoCard,
    forecastCard
  },
  props: {
    tagId: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      rankType: 'consumption'
    }
  },
  computed: {
    ...mapGetters({
      pageData: 'page/pageData'
    })
  },
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {
    changeRankType(val) {
      this.rankType = val || 'consumption'
    }
  }
}
</script>

<style lang="scss" scoped>
*::v-deep {
  .v-toolbar__title {
    color: var(--v-accent-base);
    font-weight: bold;
  }
  .v-toolbar__content {
    padding: 0;
  }
}
</style>
